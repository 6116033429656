<template>
    <div class="view pa24">
        <div class="mb20 aeName">{{ aeName }}</div>
        <div class="d-flex justify-content-between">
            <div>
                赞助商品名称：
                <el-input v-model="aesName" class="w200 mr10" placeholder="请输入商品名称关键字" />
                <el-select class="mr20 w150" v-model="statusValue" clearable>
                    <el-option v-for="item in statusData" :key="item.id" :label="item.name" :value="item.id">
                        {{ item.name }}
                    </el-option>
                </el-select>
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <el-button @click="reset">重置</el-button>

            </div>
        </div>

        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column prop="aeId" width="50" align="center" label="序号"> </el-table-column>
                <el-table-column prop="name" align="center" width="200" label="赞助的活动" show-overflow-tooltip />
                <el-table-column prop="goodsPhoto" width="130" align="center" label="赞助商品图片">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.goodsPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                            :src="scope.row.goodsPhoto"></el-image>
                        <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsName" align="center" label="赞助商品" show-overflow-tooltip />
                <el-table-column prop="goodsNum" width="130" show-overflow-tooltip align="center" label="赞助商品数量" />
                <el-table-column prop="totalPrice" width="150" show-overflow-tooltip align="center" label="赞助商品总价值">
                    <template slot-scope="scope">
                        {{ scope.row.totalPrice }}元
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" width="110" label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0">待确认</el-tag>
                        <el-tag v-if="scope.row.status == 1">已确认</el-tag>
                        <el-tag type="danger" v-if="scope.row.status == 2">已拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="visitTime" width="120" align="center" label="操作">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status === 0">
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                @confirm="setStatus(scope.row, 1)" icon="el-icon-info" title="是否继续确认该赞助？">
                                <el-button type="text" slot="reference">确认</el-button>
                            </customPopconfirm>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                @confirm="setStatus(scope.row, 2)" icon="el-icon-info" title="是否继续拒绝该赞助？">
                                <el-button type="text" class="cred ml10" slot="reference">拒绝</el-button>
                            </customPopconfirm>
                        </div>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
    </div>
</template>

<script>
import router from "../../router";
import customPopconfirm from "@/components/common/customPopconfirm";
import commonTable from "@/components/common/commonTable";
import {
    getActivityEventSupportList,
    setActivityEventSupport
} from "@/api/companyManage.js";
import { queryCompanyStaff } from "@/api/articleManagement";
export default {
    name: "visitorData",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            pickerValue: "", //选择时间
            tableData: [],
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            staffs: [],
            userId: "",
            statusData: [
                { id: 0, name: "待确认" },
                { id: 1, name: "已确认" },
                { id: 2, name: "已拒绝" },
            ],
            statusValue: "",
            aesName: "",
            aeId: "",
            aeName: "",
        };
    },
    created() {
        this.aeId = this.$route.query.aeId
        this.aeName = this.$route.query.aeName
        this.queryVisitorsData();
    },
    methods: {
        searchFun() {
            this.currentPage = 1;
            this.queryVisitorsData();
        },
        /**@method 重置 */
        reset() {
            this.aesName = "";
            this.statusValue = "";
        },
        /**@method 获取列表 */
        async queryVisitorsData() {
            let data = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                status: this.statusValue,
                aeId: this.aeId,
                goodsName: this.aesName,
                isTree: 0,
                isPage: 1
            };
            try {
                this.loading = true;
                const result = await getActivityEventSupportList(data);
                this.loading = false;
                const { total, list } = result.data.pageInfo;
                this.tableData = list;
                this.total = total;
            } catch (error) {
                this.currentPage = 1
                this.loading = false;
                this.tableData = [];
                this.total = 0;
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.queryVisitorsData();
        },
        /**@method 切换页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.queryVisitorsData();
        },
        setStatus(row, status) {
            let data = {
                status: status,
                supportId: row.supportId
            };
            setActivityEventSupport(data).then(res => {
                if (res.code === 200) {
                    this.queryVisitorsData();
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.aeName {
    font-size: 20px;
    font-weight: 600;
}
</style>